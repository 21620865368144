@use 'src/variables.scss' as vars;

.humble-chart {
  background-color: #0D0B2C;
  padding: 120px 0;
  border-radius: 40px;
  position: relative;

  @media (max-width: vars.$screen-md) {
    padding: 70px 0;
  }

  &__top {
    margin-bottom: 100px;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    padding: 0 10px;

    @media (max-width: vars.$screen-md) {
      margin-bottom: 85px;
    }
  }

  &__bot {
    margin-left: auto;
    margin-right: auto;
    max-width: 1000px;
    height: 580px;

    @media (max-width: vars.$screen-sm) {
      padding-right: 20px;
    }
    @media (max-width: vars.$screen-xs) {
      padding-right: 10px;
    }
  }
}
