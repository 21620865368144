@use 'src/variables.scss' as vars;

.app {
  overflow-x: hidden;
  background-color: #0D0B2C;
}

.bg-img {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  background-attachment: fixed;
  background-size: 1440px auto;
  background-position: center top;
  background-repeat: no-repeat;
  transition: opacity 1s ease;

  @media (max-width: vars.$screen-lg) {
    background-size: 1024px auto;
    background-position: center top 100px;
  }

  @media (max-width: vars.$screen-md) {
    background-size: 840px auto;
    background-position: center top 150px;
  }

  @media (max-width: vars.$screen-xs) {
    background-size: 600px auto;
  }
}

.ellipse {
  position: fixed;
  filter: blur(43vw);

  @supports (-moz-appearance:none) {
    opacity: 0.45;
  }

  &.blue {
    background: rgba(30, 241, 255);
    transform: rotate(-20deg);
    width: 75%;
    height: 820px;
    top: 200px;
    right: 23px;
    animation: moveBlue 15s ease-in-out infinite;

    @media (max-width: vars.$screen-md) {
      animation-duration: 10s;
      height: 600px;
      top: 150px;
    }
  }

  &.pink {
    background: rgba(112, 0, 255);
    transform: rotate(-20deg);
    height: 1642px;
    width: 48%;
    top: -246px;
    left: -50px;
    animation: movePink 15s ease-in-out 2s infinite;

    @media (max-width: vars.$screen-md) {
      animation-duration: 10s;
      height: 700px;
      top: 100px;
    }
  }

  &.green {
    background: rgba(107, 243, 73);
    width: 63%;
    height: 900px;
    top: 593px;
    right: -20px;
    animation: moveGreen 15s ease-in-out 5s infinite;

    @media (max-width: vars.$screen-md) {
      animation-duration: 10s;
      height: 450px;
      top: 493px;
    }
  }
}

@keyframes moveBlue {
  0% {
    transform: translateX(0) rotate(-20deg);
  }
  25% {
    transform: translateX(-65%) rotate(-20deg);
  }
  75% {
    transform: translateX(35%) rotate(-20deg);
  }
  100% {
    transform: translateX(0) rotate(-20deg);
  }
}

@keyframes movePink {
  0% {
    transform: translateX(0) rotate(-20deg);
  }
  25% {
    transform: translateX(-60%) rotate(-20deg);
  }
  75% {
    transform: translateX(50%) rotate(-20deg);
  }
  100% {
    transform: translateX(0) rotate(-20deg);
  }
}

@keyframes moveGreen {
  0% {
    transform: translateX(0) rotate(-20deg);
  }
  25% {
    transform: translateX(-50%) rotate(-20deg);
  }
  75% {
    transform: translateX(40%) rotate(-20deg);
  }
  100% {
    transform: translateX(0) rotate(-20deg);
  }
}
