@use 'src/variables.scss' as vars;

.grow {
  background-color: #62C759;
  padding: 100px 20px 80px;
  position: relative;
  border-radius: 40px 40px 0px 0px;

  @media (max-width: vars.$screen-md) {
    padding: 60px 25px 16px;
  }

  &__top {
    font-weight: 700;
    font-size: 64px;
    line-height: 100%;
    text-transform: uppercase;
    max-width: 728px;

    @media (max-width: vars.$screen-md) {
      font-size: 48px;
    }
  }

  &__bot {
    margin-top: 126px;
    margin-bottom: 20px;

    @media (max-width: vars.$screen-md) {
      margin-top: 96px;
    }

    .contacts {
      display: flex;
      flex-direction: column;

      &__top {
        font-size: 16px;
        font-weight: 700;
      }

      a {
        font-size: 48px;
        margin-top: 5px;
        width: fit-content;

        @media (max-width: vars.$screen-md) {
          font-size: 36px;
        }

        &:nth-of-type(2) {
          margin-top: 16px;
        }

        &:hover {
          color: #0D0A2C;
        }
      }

    }

    .copyright {
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      position: absolute;
      bottom: 0;
      right: 120px;

      @media (max-width: vars.$screen-md) {
        position: unset;
        margin-top: 96px;
      }
    }
  }
}
