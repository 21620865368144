@use 'src/variables.scss' as vars;

.humble-list {
  display: flex;
  max-width: 371px;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 96px;

  .list {
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 24px;
    line-height: 29px;
    width: 305px;

    :not(.list-title) {
      font-family: vars.$inter;
    }

    .list-title {
      font-weight: 700;
    }
  }
}