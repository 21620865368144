body {
  margin: 0;
  font-family: 'Blender Pro', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;

  color: #F1F1F1;
}

h1 {
  margin-block-start:0; margin-block-end:0; margin-inline-start:0; margin-inline-end:0;
}

a {
  color: #F1F1F1;
  text-decoration: underline transparent;
  transition: text-decoration 0.5s ease;

  &:hover {
    text-decoration: underline;
  }
}

.contents {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
