@use 'src/variables.scss' as vars;

.about-team {
  padding: 200px 20px 240px;

  @media (max-width: vars.$screen-sm) {
    padding: 110px 10px 136px;
  };

  .contents {
    max-width: 1140px;
  }

  &__top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-right: 79px;
    margin-bottom: 74px;

    @media (max-width: vars.$screen-md) {
      flex-direction: column;
      padding-right: 0;
    };

    .title {
      font-weight: 700;
      font-size: 64px;
      line-height: 77px;
      margin-bottom: 90px;

      @media (max-width: vars.$screen-md) {
        font-size: 48px;
        line-height: 58px;
      };
    }
  }

  &__bot {
    .box {
      border-radius: 40px;
      position: relative;

      display: grid;
      grid-template-columns: repeat(auto-fit, 156px);
      grid-auto-rows: 110px;
      justify-content: space-around;
      align-items: center;
      padding: 52px 24px 52px;

      @media (max-width: vars.$screen-md) {
        padding-top: 90px;
      }

      &.blue {
        background-color: #34A1CD;
      }
      &.green {
        background-color: #4BAA5A;
      }

      .svg-wrapper {
        display: flex;
        justify-content: center;
        align-items: flex-start;
      }

      .label {
        position: absolute;
        top: 30px;
        left: 40px;
        font-size: 16px;
        font-weight: bold;

        @media (max-width: vars.$screen-md) {
          top: 50px;
        }
      }
    }
  }
}