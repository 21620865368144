@use 'src/variables.scss' as vars;

.first-view {
  position: relative;

  &__wrapper {
    padding: 25px 20px 170px;
    @media (max-width: vars.$screen-sm) {
      padding: 20px 10px;
    }
  }

  .contents {
    &__main {
      display: flex;
      margin-left: auto;
      margin-right: auto;
      padding-left: 163px;
      justify-content: space-between;
      flex-wrap: wrap;

      @media (max-width: vars.$screen-md) {
        margin-top: 129px;
        padding-left: unset;
      }
    }
  }

  .landing-wrapper {
    height: 100vh;

    .h1-wrapper {
      width: 100%;
      height: calc(100% - 30px);
      display: flex;
      align-items: center;

      h1 {
        font-weight: 700;
        font-size: 96px;
        line-height: 100%;
        max-width: 810px;
        margin-bottom: 100px;

        @media (max-width: vars.$screen-md) {
          font-size: 64px;
          line-height: 79.5%;
        }

        @media (max-width: vars.$screen-xs) {
          font-size: 52px;
        }
      }
    }
  }

  header {
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    height: 30px;
    position: relative;

    a:not(:last-of-type) {
      margin-right: 30px;
    }

    .links {
      @media (max-width: vars.$screen-md) {
        display: none;
      }
    }

    .burger {
      display: none;
      @media (max-width: vars.$screen-md) {
        display: block;
      }
    }
  }
}

.mobile-menu {
  position: absolute;
  padding: 12px 10px 42px 30px;
  width: calc(320px - 10px - 30px);
  height: calc(100vh - 12px - 42px);
  right: -600px;
  top: 0;
  background: #62C759;
  border-radius: 40px 0px 0px 40px;
  transition: right 500ms cubic-bezier(.6, .05, .28, .91);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .cross {
    margin-left: auto;
    width: 30px;
  }

  &__top {
    padding: 58px 0 0;
    margin-bottom: auto;
    font-size: 36px;
    line-height: 43.2px;
    display: flex;
    flex-direction: column;

    a {
      margin-bottom: 24px;
    }
  }

  &__middle {
    padding-bottom: 47px;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    flex-direction: column;

    a {
      margin-bottom: 14px;
    }
  }

  &__bot {
    font-size: 16px;
    line-height: 19px;
    width: 260px;
  }
}
