$screen-xl: 1440px;
$screen-lg-2: 1280px;
$screen-lg: 1024px;
$screen-md-2: 960px;
$screen-md: 840px;
$screen-sm: 600px;
$screen-xs: 480px;
$screen-xxs: 360px;

$inter: Inter, sans-serif;
